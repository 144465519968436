import React, { useRef } from 'react';

import video_demo_1 from '../assets/video_demo_curious_beginnings.mp4';
import video_demo_2 from '../assets/video_demo_blackwater_creek.mp4';
import video_demo_3 from '../assets/video_demo_vampires_curse.mp4';
// import video_demo_4 from '../assets/video_demo_port_nyanzaru.mp4';

import AudioDemo from '../components/AudioDemo';
import Steps from '../components/Steps';
import Hero from '../components/Hero';
import Pricing from '../components/Pricing';
import Testimonials from '../components/Testimonials';

const HomePage = () => {
  window.scrollTo(0, 0);

  const audioDemoRef = useRef(null);
  const scrollToAudioDemo = () => {
    // Step 3: Create a scroll function
    audioDemoRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const sample_text_1 = `Last session, you found yourselves in the small, rural town of Trostenwald. Caleb, a human wizard, and his companion Nott, a goblin rogue disguised as a halfling, were staying at the Nestled Nook Inn after a rough journey. In the morning, they went down to breakfast and met Jester, a blue tiefling cleric; Beauregard, a human monk; and Fjord, a half-orc sailor. The group began to get acquainted over their meal.
    While there, you encountered Mollymauk, a flamboyant lavender tiefling adorned with elaborate clothing and tattoos, and Yasha, a tall, muscular woman with an imposing presence. They were promoting the Fletching and Moondrop Traveling Carnival of Curiosities and invited you to attend their performance that evening.
    Deciding to go together, you spent the day exploring Trostenwald, sampling local ales, and browsing shops. As dusk fell, you arrived at the carnival. Inside the large blue tent, you joined a crowd of eager townsfolk. The show featured dazzling acts: the serpentine acrobatics of the Knot Sisters, a fiery dance by Orna the Fire Fairy, and finally, the enchanting song of Toya, a young dwarven girl with a haunting voice.
    However, during Toya's performance, an old man in the audience began to convulse and grotesquely transformed into a monstrous, undead creature. Chaos erupted as he attacked the crowd, and a woman he assaulted also transformed into a similar monstrosity. Amidst the panic, you all sprang into action. Drawing upon your abilities, you engaged the creatures in battle. Thanks to your combat expertise, you managed to defeat the abominations before more harm could be done.
    As the dust settled, the Crownsguard arrived, led by Watchmaster Bryce. Surveying the scene and the ruined performance, Bryce declared the carnival under suspicion and informed you that an investigation would commence. You were instructed not to leave Trostenwald and to stay at the Nestled Nook Inn until the matter was resolved.
    Now, with the carnival halted and under scrutiny, and yourselves drawn into a mysterious and deadly incident, you must contemplate your next move under the watchful eyes of the Crownsguard.`;

  const sample_text_2 = `Last session, you, Oliver Barr, a private investigator, and Bruce Buck, a famous stuntman, were summoned to Miskatonic University by Professor Ernest McTavish. Concerned about the mysterious disappearance of your mutual acquaintance, Professor Henry Rhodes, McTavish shared a troubling letter from Henry. Dated June 22nd, it revealed that Henry had been delayed at a dig site in Blackwater Creek due to unusually hostile local farmers and warned against sending additional field team members.
    Intrigued and worried, you spoke with two of Henry's students, Agnes Somaz and Clarence Welliver. They explained that Henry had sent them back to Arkham, citing tensions with the locals, while he and his wife, Abigail, remained behind. Visiting a third student, Walt Gehrig, you sensed unease and noticed odd behavior concerning his supposedly ill mother, but gleaned little useful information.
    Determined to uncover the truth, you embarked on a journey to Blackwater Creek, traveling separately—Oliver on his motorcycle and Bruce in his Rolls Royce. Along the way, Oliver encountered John Merritt, a veterinarian who warned of a mysterious illness afflicting local animals, resulting in stillbirths and strange mold growths. He advised caution around the Karmody family, known for their violent tendencies and suspected moonshining, and recommended seeking out the more hospitable Jarvey family.
    Reuniting in Blackwater Creek at dusk, you stumbled upon two children being bullied. Intervening, you discovered the kids had collected a jar of muddy creek water teeming with unusual white flecks that consumed a worm before your eyes. Taking the jar for further examination, you encountered Sheriff Dick Sprouston, who appeared unwell with dark pustules on his skin. He mentioned the recent disappearance of Widow Hawkins and suggested staying with the Jarveys for the night.
    Investigating Widow Hawkins's empty shack, you found signs of a struggle, including overturned furniture and bloodstains leading out the door. Beneath her bed, you discovered an abandoned shotgun but no clues to her whereabouts.
    Arriving at the Jarvey farm, Malcolm Jarvey welcomed you warmly. He requested your assistance in putting down an infected pig showing the same black pustules you'd seen on the sheriff. After helping him euthanize and burn the animal to prevent the spread of disease, he introduced you to Brutus, his prized giant boar. Over dinner, Malcolm and his wife, Edith, revealed that Henry and Abigail Rhodes had stayed with them previously but hadn't been seen since.
    Settling in for the night at the Jarvey farm, you prepared to delve deeper into the unsettling mysteries of Blackwater Creek, hoping to find answers about the strange illness and the fate of Henry and Abigail.`;

  const sample_text_3 = `Last session, you—Boren, a dwarven paladin, and Wendell, a half-elven farmer—met for the first time in the River Shining Tavern in Daggerford. Though Wendell seemed reluctant to engage, Boren, ever hospitable, bought Wendell a drink and you struck up a conversation. Soon after, Elliot, the barkeep, informed you that Lady Morwen, the Duchess of Daggerford, was offering a reward of 2,000 gold pieces to anyone who could peacefully rid the town of a group of troublesome outsiders who recently set up camp.
    Accepting the task, you proceeded to the camp and encountered Stanimir, the elder of the Vistani travelers. Instead of leaving, Stanimir shared a captivating tale of a prince they once saved and extended an invitation for you to meet Madam Eva in the land of Barovia, assuring you that she would have the answers you sought. You agreed to accompany the Vistani, joining their caravan as they journeyed eastward.
    During the journey, Wendell displayed unusual abilities, conjuring a shadowy longbow to expertly hunt a hare, which caught Boren's attention. As night fell, you made camp at the edge of the Misty Forest. Boren summoned his steed, Zoltan, though the majestic war boar appeared with a twisted visage, unsettling both of you. Settling in for the night, you slept by the campfire.
    At dawn, you awoke to an eerie mist enveloping the area and discovered that the Vistani and their wagons had vanished without a trace. The surroundings had changed dramatically; the familiar sights of the Sword Coast were replaced with dark, unfamiliar woods shrouded in fog. Confused and wary, you set out along the only path visible.
    As you ventured forward, a foul stench led you to the corpse of a young man clutching a crumpled letter. Reading it, you learned that it was a plea from Kolyan Indirovich, the Burgomaster of Barovia, begging for aid against a powerful vampire who had bitten his adopted daughter, Ireena Kolyana. The letter spoke of a curse that had plagued their land for over 400 years.
    Pressing on, you heard the howls of wolves drawing nearer. Suddenly, two wolves lunged at you from the mist. During the fierce battle, both of you sustained injuries, but you managed to slay the wolves. To your horror, the slain wolves transformed into human corpses before your eyes. Then, a massive white wolf with glowing red eyes emerged from the shadows, accompanied by several smaller wolves. The great wolf observed you intently but, after Boren called out, it turned and vanished into the mist, taking the other wolves with it.
    Wounded and unsettled by the strange events, you continued along the path. The oppressive mist seemed to guide you forward, closing in behind you. Eventually, you arrived at the outskirts of a grim village. A toppled wooden sign revealed your destination: "The Village of Barovia."`;

  // const sample_text_4 = `Last session, you arrived in Port Nyanzaru aboard Chena's ship, the Brass Phoenix, accompanied by Syndra Sylvain. Your mission was clear: find Artus Cimber and halt the death curse plaguing the world. The vibrant city overwhelmed your senses with its exotic sights, sounds, and oppressive heat.
  //   Syndra introduced you to Tiff, a seasoned local guide familiar with the dangers of Chult. Agreeing to assist you, Tiff led you through the bustling streets to meet Wakanga O'Tamu, a merchant prince and ally. In his opulent villa, Wakanga offered his aid in locating Artus Cimber if you would do him two favors: escort a man named Omala from the Dye Works to the Golden Throne, and deal with pirate ships—the Dragonfang, the Emerald Eye, and the Sturge—that threatened his merchant fleet.
  //   Accepting his request, you ventured to the Dye Works, a kaleidoscope of colors overlooking the sea. There, you found Omala hiding, fearful for his life due to a misdeed involving a monkey named Beki. After cleverly disguising him, you began the cautious journey back. However, assassins ambushed you en route. With swift action, you thwarted their attack, showcasing your combat prowess.
  //   Upon delivering Omala to the Golden Throne, you faced Kwayothé, a stern merchant prince who desired Omala's death. She offered a grim bargain: in exchange for sparing Omala, you were to discreetly eliminate Shago, a man stationed at Fort Beluarian, within 15 days. The weight of this moral quandary pressed upon you, recognizing the fort as a Flaming Fist stronghold known for its mercenary forces.
  //   Returning to the Brass Phoenix, you discussed the implications of Kwayothé's demand. Weighing your options, you considered the necessity of staying in Wakanga's good graces and the potential conflicts ahead. Deciding to take a momentary respite, you embraced the idea of attending the famed dinosaur races of Port Nyanzaru.
  //   As night fell, with the city alive around you, you headed toward the races. Chena's sister, Ala, and her companion, Daren, eagerly joined, some of you contemplating participating in the thrilling event. Amidst the looming challenges, you allowed yourselves a brief moment of excitement, knowing that soon you'd have to decide your path forward in the untamed lands of Chult.`;

  return (
    <div className="min-h-screen flex flex-col items-center">
      <div className="max-w-7xl p-8 w-full">
        <Hero onButtonClick={scrollToAudioDemo} />

        <div className="px-0 sm:px-6 lg:px-8 py-10 sm:py-12 lg:py-16">
          <h3 className="font-inknut font-medium text-2xl text-gray-800 md:text-3xl dark:text-gray-200 text-center mb-5 md:mb-10 ">
            How it Works
          </h3>
          <Steps />
        </div>

        <div
          className="px-0 sm:px-6 lg:px-8 py-10 sm:py-12 lg:py-16 scroll-mt-28"
          ref={audioDemoRef}
        >
          <h3 className="font-inknut font-medium text-2xl text-gray-800 md:text-3xl dark:text-gray-200 text-center mb-5 md:mb-10">
            Sample Recaps Generated with Scrybe
          </h3>
          <div className="flex flex-col gap-10">
            <AudioDemo
              id="video-demo"
              video_source={video_demo_1}
              title="Recap: Curious Beginnings"
              text={sample_text_1}
            />
            <AudioDemo
              id="video-demo"
              video_source={video_demo_2}
              title="Recap: Blackwater Creek"
              text={sample_text_2}
              reversed
            />
            <AudioDemo
              id="video-demo"
              video_source={video_demo_3}
              title="Recap: Vampire's Curse"
              text={sample_text_3}
            />
            {/* <AudioDemo
              id="video-demo"
              video_source={video_demo_4}
              title="Recap: Port Nyanzaru"
              text={sample_text_4}
              reversed
            /> */}
          </div>
        </div>

        {/* <Testimonials /> */}
        <div className="px-0 sm:px-6 lg:px-8 py-10 sm:py-12 lg:py-16">
          <h3 className="font-inknut font-medium text-2xl text-gray-800 md:text-3xl dark:text-gray-200 text-center mb-5 md:mb-10">
            Testimonials
          </h3>
          <Testimonials />
        </div>

        {/* <Pricing /> */}
        <div className="px-0 sm:px-6 lg:px-8 py-10 sm:py-12 lg:py-16">
          <h3 className="font-inknut font-medium text-2xl text-gray-800 md:text-3xl dark:text-gray-200 text-center mb-5 md:mb-10">
            Pricing
          </h3>
          <Pricing />
        </div>
      </div>
    </div>
  );
};

export { HomePage };
