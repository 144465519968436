import { ReactComponent as ScrybeLogo } from '../assets/Scrybe_Logo.svg';

// import react link
import { Link } from 'react-router-dom';

function DevLogPage() {
  window.scrollTo(0, 0);

  return (
    <div className="max-w-4xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
      <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14">
        <h1 className="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut max-w-md mx-auto">
          Dev Log
        </h1>
        <p className="mt-1 text-gray-600 dark:text-gray-400 max-w-[16em] mx-auto">
          Last updated: November 2nd, 2024
        </p>
      </div>

      <div>
        {/* v1.2 */}
        <div>
          <h2 className="text-lg font-semibold dark:text-white flex items-center">
            <span className="font-bold">Version</span>
            <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-teal-100 text-teal-800 inline-flex items-center justify-center">
              v1.2
            </span>
          </h2>
          <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-xs">
            November 2, 2024
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                📝 Added option to select third person perspective
              </span>{' '}
              - Great for recaps directed to an audience. You can also optionally specify your adventuring party's name instead of defaulting to "the party". 
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">⬆️ Increased recap detail and length</span>
            </li>
            {/* decrease */}
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">💾 Massively optimized video file size</span>{' '} - Video recaps are now ~65% smaller, now consistently fitting into Discord's 10MB upload limit
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">⚙️ Increased TTS stability</span>{' '}
              - reduced fast talking and unusual narrator inflections
            </li>
            <li className="mb-2 dark:text-gray-400">
              Swapped to higher quality TTS model for some narrators
            </li>
          </ul>
        </div>

        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        {/* v1.1 */}
        <div>
          <h2 className="text-lg font-semibold dark:text-white flex items-center">
            <span className="font-bold">Version</span>
            <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-violet-100 text-violet-800 inline-flex items-center justify-center">
              v1.1
            </span>
          </h2>
          <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-xs">
            September 23, 2024
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                🛠️ Massively improved recap summarization quality
              </span>{' '}
              (Upgraded from GPT-4o to o1-preview)
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">🗣️ Added new narrator voices:</span>{' '}
              Arthur, Gideon, Jack, Jessica, Klaus, Felix, and Reginald
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                🔍 Added search bar, filters, and pagination
              </span>{' '}
              to the "My Recaps" page for better organization
            </li>
            <li className="mb-2 dark:text-gray-400">
              Added option to sign up with email and password instead of just
              Google
            </li>
            <li className="mb-2 dark:text-gray-400">
              Made narrator tags more descriptive
            </li>
          </ul>
        </div>

        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        {/* v1.0 */}
        <div>
          <h2 className="text-lg font-semibold dark:text-white flex items-center">
            <span className="font-bold">Version</span>
            <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-blue-100 text-blue-800 inline-flex items-center justify-center">
              v1.0
            </span>
          </h2>
          <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-xs">
            May 20, 2024
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                ✏️ You can now manually edit the text recaps
              </span>{' '}
              - Correct spelling or add custom text before proceeding to
              generate the audio and video recaps
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                🔊 Added audio previews for narrators and background music
              </span>
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">🗣️ Added new narrator voices:</span>{' '}
              Alfie, Carter, Claria, Eleanor, Fizzlebert, George, Mira, Nasim,
              Ronan, Silas, and Von
            </li>
            <li className="mb-2 dark:text-gray-400">
              <span className="font-medium">
                🎵 Added new background music:
              </span>{' '}
              Adventure, Basilica of the Heavens, Beautiful Village, Cursed
              Island, Dwarven King's Tomb, Geof the Blacksmith, Link Street,
              Magical Forest, Old Creek Grove, Protecting Neverwinter, Pulse of
              the Unknown, Quaint Quest, Queen of the Dead, Spirits Refuge, The
              City of Akaton, The Dragon Hoard, The Herbalist, The Long Path,
              The White City, Tiny Kingdom, Uncertain Tidings, Whispering Door,
              Winterlight, Without God, and Wode
            </li>
            <li className="mb-2 dark:text-gray-400">
              Added an{' '}
              <Link
                to="/faq"
                className="text-gray-800 font-medium dark:text-blue-400 hover:underline"
              >
                FAQ page
              </Link>
            </li>
          </ul>
        </div>

        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        {/* v0.1 */}
        <div>
          <h2 className="text-lg font-semibold dark:text-white flex items-center">
            <span className="font-bold">Alpha Version</span>
            <span className="ml-2 rounded-full py-1 px-1.5 text-xs bg-gray-100 text-gray-800 inline-flex items-center justify-center">
              v0.1
            </span>
          </h2>
          <p className="mb-5 text-gray-600 dark:text-gray-400 uppercase text-xs">
            February 6, 2024
          </p>
          <ul className="list-disc pl-8 mb-5">
            <li className="mb-2 dark:text-gray-400">
              Scrybe officially launched to the public. Woo hoo! 🎉
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DevLogPage;
