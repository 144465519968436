import { useParams, Link } from 'react-router-dom';
import { auth, storage, db } from '../firebaseConfig';
import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import {
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  collection,
  getDocs,
  addDoc,
  Timestamp,
  orderBy,
  query,
  onSnapshot,
} from 'firebase/firestore';

import { ReactComponent as ScrybeLogo } from '../assets/Scrybe_Logo.svg';
import { ReactComponent as SpilledInkIcon } from '../assets/Spilled_Ink.svg';

import moment from 'moment';

import { modifyTokenBalance } from '../utils/FirebaseFunctionsHelper';

import BreadCrumb from '../components/BreadCrumb';
import TextBox from '../components/TextBox';

import Lottie from 'lottie-react';
import loadingAnimationBlue from '../assets/loading_lottie_blue.json';
import loadingAnimationRed from '../assets/loading_lottie_red.json';
import loadingAnimationGreen from '../assets/loading_lottie_green.json';
import loadingAnimationYellow from '../assets/loading_lottie_yellow.json';

import { useSession } from '../SessionContext';

import { IoCloseCircle, IoTrashSharp } from 'react-icons/io5';
import { FaTimesCircle } from 'react-icons/fa';
import { IoIosCloseCircle } from 'react-icons/io';

import { deleteSession } from '../utils/FirebaseFunctionsHelper';

async function fetchCampaignData(campaignId) {
  try {
    // Fetch the campaign document from Firestore
    const docRef = await getDoc(
      doc(db, `users/${auth.currentUser.uid}/campaigns/${campaignId}`)
    );

    if (docRef.exists) {
      return docRef.data();
    } else {
      console.error('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching campaign data:', error);
    return null;
  }
}

function CampaignPage() {
  const { campaignId } = useParams();
  const [campaign, setCampaign] = useState(null);

  const [sessions, setSessions] = useState(null);

  const [sessionsStatus, setSessionsStatus] = useState({});

  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedSessionId, setSelectedSessionId] = useState('');

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Sorting and filtering state
  const [sortOption, setSortOption] = useState('date-desc');
  const [showReviewRecapOnly, setShowReviewRecapOnly] = useState(false);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    // Reset current page when items per page changes
    setCurrentPage(1);
  }, [itemsPerPage]);

  useEffect(() => {
    // Assuming sessions is a state variable that, when changed, should update session status
    if (!sessions) return;
    sessions.forEach((session) => {
      const unsub = onSnapshot(
        doc(
          db,
          `users/${auth.currentUser.uid}/campaigns/${campaignId}/sessions`,
          session.id
        ),
        (doc) => {
          const data = doc.data();

          setSessionsStatus((prevStatus) => ({
            ...prevStatus,
            [doc.id]: {
              transcribing: data?.transcription === null,
              summarizing:
                data?.transcription !== null &&
                data?.recaps?.recap1?.summary === null,
              waitingForManualEdit:
                data?.transcription !== null &&
                data?.recaps?.recap1?.summary !== null &&
                data?.recaps?.recap1?.manualEdit === true,
              narrating:
                data?.transcription !== null &&
                data?.recaps?.recap1?.summary !== null &&
                data?.recaps?.recap1?.narrationStoragePath === null &&
                data?.recaps?.recap1?.manualEdit === false,
              videoProcessing:
                data?.transcription !== null &&
                data?.recaps?.recap1?.summary !== null &&
                data?.recaps?.recap1?.narrationStoragePath !== null &&
                data?.recaps?.recap1?.narratedVideoStoragePath === null,
            },
          }));
        }
      );

      return () => unsub(); // Cleanup listener on unmount
    });
  }, [sessions]); // Dependencies array, useEffect runs when `sessions` changes

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in

        // Fetch the campaign data from Firestore
        fetchCampaignData(campaignId).then((data) => setCampaign(data));

        // Real-time update subscription for sessions
        const sessionCollection = collection(
          db,
          `users/${user.uid}/campaigns/${campaignId}/sessions`
        );
        const sessionQuery = query(
          sessionCollection,
          orderBy('timeCreated', 'desc')
        );
        const unsubscribeSessions = onSnapshot(sessionQuery, (snapshot) => {
          const sessionData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setSessions(sessionData);
        });

        // Cleanup subscription on unmount or auth state change
        return () => {
          unsubscribeSessions();
        };
      } else {
        // User is not authenticated
        console.log('User not authenticated');
        setSessions([]); // Optionally clear sessions if the user is logged out
      }
    });

    // Clean up the auth listener when the component is unmounted
    return () => unsubscribeAuth();
  }, [campaignId]); // Dependency array, useEffect runs when `campaignId` changes

  // Apply filtering and sorting
  let filteredSessions = sessions ? [...sessions] : [];

  // Filter sessions if showReviewRecapOnly is true
  if (showReviewRecapOnly) {
    filteredSessions = filteredSessions.filter((session) => {
      return sessionsStatus[session.id]?.waitingForManualEdit;
    });
  }

  // Filter by search query
  if (searchQuery) {
    filteredSessions = filteredSessions.filter((session) =>
      session.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const sortOptions = [
    { value: 'date-desc', label: 'Date (Newest first)' },
    { value: 'date-asc', label: 'Date (Oldest first)' },
    { value: 'name-asc', label: 'Name (A - Z)' },
    { value: 'name-desc', label: 'Name (Z - A)' },
  ];

  const selectedSortLabel =
    sortOptions.find((option) => option.value === sortOption)?.label || 'Sort';

  // Sort sessions based on sortOption
  switch (sortOption) {
    case 'date-asc':
      filteredSessions.sort(
        (a, b) => a.timeCreated.seconds - b.timeCreated.seconds
      );
      break;
    case 'date-desc':
      filteredSessions.sort(
        (a, b) => b.timeCreated.seconds - a.timeCreated.seconds
      );
      break;
    case 'name-asc':
      filteredSessions.sort((a, b) =>
        a.name.trim().localeCompare(b.name, undefined, { sensitivity: 'base' })
      );
      break;
    case 'name-desc':
      filteredSessions.sort((a, b) =>
        b.name.trim().localeCompare(a.name, undefined, { sensitivity: 'base' })
      );
      break;
    default:
      break;
  }

  // Calculate total pages based on filteredSessions
  const totalPages = filteredSessions
    ? Math.ceil(filteredSessions.length / itemsPerPage) || 1
    : 1;

  // Ensure current page is valid
  useEffect(() => {
    if (filteredSessions) {
      const newTotalPages =
        Math.ceil(filteredSessions.length / itemsPerPage) || 1;
      if (currentPage > newTotalPages) {
        setCurrentPage(newTotalPages);
      }
    }
  }, [filteredSessions, itemsPerPage]);

  // Get current sessions for current page
  const indexOfLastSession = currentPage * itemsPerPage;
  const indexOfFirstSession = indexOfLastSession - itemsPerPage;
  const currentSessions = filteredSessions
    ? filteredSessions.slice(indexOfFirstSession, indexOfLastSession)
    : [];

  return (
    <div className="h-full max-w-7xl px-8 sm:px-12 lg:px-16 pt-20 pb-4 sm:pb-12 lg:pb-16 md:pt-24 mx-auto">
      <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14">
        <h1 className="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut">
          My Recaps
        </h1>
      </div>

      {/* Pagination Controls */}
      <div className="max-w-4xl mx-auto pb-4 flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3">
        {/* Left Side: Items per page selector and filter button */}
        <div className="flex items-center gap-3 w-full sm:w-auto">
          {/* Search bar */}
          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="py-2 px-3 ps-11 pr-8 block w-full border-gray-200 rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
            />
            <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-4">
              <svg
                className="shrink-0 w-4 h-4 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="m21 21-4.3-4.3" />
              </svg>
            </div>

            {searchQuery && (
              <button
                onClick={() => setSearchQuery('')}
                className="absolute inset-y-0 end-0 flex items-center pr-3 text-gray-400 hover:text-gray-600 focus:outline-none"
                aria-label="Clear search"
              >
                <IoIosCloseCircle className="w-4 h-4" />
              </button>
            )}
          </div>
          {/* Filter button */}
          <div className="hs-dropdown relative inline-block [--auto-close:inside]">
            <button
              id="hs-dropdown-filter"
              type="button"
              className="hs-dropdown-toggle py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
              aria-haspopup="menu"
              aria-expanded="false"
              aria-label="Dropdown"
            >
              <svg
                className="hs-dropdown-open:rotate-180 shrink-0 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M3 6h18" />
                <path d="M7 12h10" />
                <path d="M10 18h4" />
              </svg>
              Filter
              {/* Show count if any filters are active */}
              {showReviewRecapOnly && (
                <span className="pl-2 border-l border-gray-200">
                  <ScrybeLogo className="w-4 h-4" />
                </span>
              )}
            </button>

            <div
              className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 border hidden min-w-48 bg-white shadow-md rounded-lg z-10 dark:bg-neutral-800 dark:border dark:border-neutral-700 mt-2"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="hs-dropdown-filter"
            >
              {/* Sorting options */}
              <div className="flex flex-col gap-y-2.5 py-2.5 px-3 w-52">
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Sort by
                  </label>
                  <div className="max-w-sm space-y-3">
                    {/* Sort by Dropdown */}
                    <div className="hs-dropdown relative inline-flex w-full">
                      <button
                        id="hs-dropdown-sort-options"
                        type="button"
                        className="hs-dropdown-toggle py-2 px-3 inline-flex items-center justify-between w-full gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 dark:bg-neutral-900 dark:text-white dark:border-neutral-700 dark:hover:bg-neutral-800"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        aria-label="Sort Options"
                      >
                        {selectedSortLabel}
                        <svg
                          className="hs-dropdown-open:rotate-180 w-4 h-4 transition-transform duration-200"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="m6 9 6 6 6-6" />
                        </svg>
                      </button>
                      <div
                        className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden w-full bg-white shadow-md rounded-lg p-1 space-y-0.5 mt-2 dark:bg-neutral-900 dark:shadow-lg z-20 absolute" // Added z-20 and absolute
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="hs-dropdown-sort-options"
                      >
                        {sortOptions.map((option) => (
                          <button
                            key={option.value}
                            className={`w-full flex items-center justify-start py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-800 ${
                              sortOption === option.value
                                ? 'font-semibold bg-gray-100 dark:bg-neutral-800'
                                : ''
                            }`}
                            role="menuitem"
                            onClick={() => setSortOption(option.value)}
                          >
                            {option.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Recaps per page */}
                <div className="">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Items per page
                  </label>
                  <div className="max-w-sm space-y-3">
                    <div className="hs-dropdown relative inline-flex w-full">
                      <button
                        id="hs-dropdown-items-per-page"
                        type="button"
                        className="hs-dropdown-toggle w-full py-2 px-3 flex justify-between items-center text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        aria-label="Items per page"
                      >
                        {itemsPerPage}
                        <svg
                          className="hs-dropdown-open:rotate-180 w-4 h-4 transition-transform duration-200"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="m6 9 6 6 6-6" />
                        </svg>
                      </button>
                      <div
                        className="hs-dropdown-menu w-full transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-1 space-y-0.5 mt-2"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="hs-dropdown-items-per-page"
                      >
                        {[5, 10, 20, 100].map((number) => (
                          <button
                            key={number}
                            className={`w-full flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 ${
                              itemsPerPage === number
                                ? 'font-semibold bg-gray-100'
                                : ''
                            }`}
                            role="menuitem"
                            onClick={() => setItemsPerPage(number)}
                          >
                            {number}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Filter options */}
              <div className="py-2.5 px-3 border-t">
                <label className="flex gap-2 items-center justify-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={showReviewRecapOnly}
                    onChange={(e) => setShowReviewRecapOnly(e.target.checked)}
                    className="h-4 w-4 text-gray-800 focus:ring-gray-500 border-gray-300 rounded dark:bg-neutral-900 dark:border-neutral-600 cursor-pointer"
                  />
                  <span className="text-sm text-gray-800 dark:text-neutral-200">
                    Ready for review
                  </span>
                  <span className="">
                    <ScrybeLogo className="w-4 h-4" />
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* Prev and Next Buttons */}
        {totalPages > 1 && (
          <div className="hidden sm:inline-flex gap-x-2 justify-center w-full sm:w-auto">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
            >
              <svg
                className="shrink-0 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m15 18-6-6 6-6" />
              </svg>
              Prev
            </button>
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
            >
              Next
              <svg
                className="shrink-0 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </button>
          </div>
        )}
      </div>
      {/* End Pagination Controls */}

      {sessions ? (
        <>
          {/* Sessions */}
          <div className="grid grid-cols-1 gap-4 max-w-4xl mx-auto">
            {currentSessions.map((session) => (
              <Link
                to={`/campaigns/${campaignId}/${session.id}`}
                key={session.id}
              >
                <div className="group flex py-4 px-6 items-center justify-between h-full rounded-md border border-gray-200 shadow-sm hover:bg-gray-50 dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                  {/* Name and Date */}
                  <div>
                    <h3 className="text-lg font-semibold text-gray-800 line-clamp-1 dark:text-gray-300 dark:hover:text-white font-cardo">
                      {session.name}
                    </h3>
                    <p className="text-gray-500 line-clamp-1 text-sm">
                      {moment(session.timeCreated.toDate()).format(
                        'MMMM Do, YYYY'
                      )}
                    </p>
                  </div>

                  {/* Right Side */}
                  <div className="flex flex-row gap-4 ml-4 items-center">
                    {/* Status Spinner */}
                    {sessionsStatus[session.id]?.transcribing && (
                      <div className="flex items-center justify-center gap-3">
                        <span className="text-xs whitespace-nowrap xs:block hidden">
                          Transcribing
                        </span>
                        <div className="flex items-center justify-center w-5 h-5 relative">
                          <Lottie
                            animationData={loadingAnimationBlue}
                            autoplay={true}
                            loop={true}
                            className="w-6 h-6 absolute -mr-0.5 -mb-0.5"
                          />
                        </div>
                      </div>
                    )}

                    {sessionsStatus[session.id]?.summarizing && (
                      <div className="flex items-center justify-center gap-3">
                        <span className="text-xs whitespace-nowrap xs:block hidden">
                          Summarizing
                        </span>
                        <div className="flex items-center justify-center w-5 h-5 relative">
                          <Lottie
                            animationData={loadingAnimationRed}
                            autoplay={true}
                            loop={true}
                            className="w-6 h-6 absolute -mr-0.5 -mb-0.5"
                          />
                        </div>
                      </div>
                    )}

                    {sessionsStatus[session.id]?.waitingForManualEdit && (
                      <div className="flex items-center justify-center gap-3">
                        <span className="text-xs whitespace-nowrap xs:block hidden">
                          Review Recap
                        </span>
                        <ScrybeLogo className="w-5 h-5" />
                      </div>
                    )}

                    {sessionsStatus[session.id]?.narrating && (
                      <div className="flex items-center justify-center gap-3">
                        <span className="text-xs whitespace-nowrap xs:block hidden">
                          Narrating
                        </span>
                        <div className="flex items-center justify-center w-5 h-5 relative">
                          <Lottie
                            animationData={loadingAnimationYellow}
                            autoplay={true}
                            loop={true}
                            className="w-6 h-6 absolute -mr-0.5 -mb-0.5"
                          />
                        </div>
                      </div>
                    )}

                    {sessionsStatus[session.id]?.videoProcessing && (
                      <div className="flex items-center justify-center gap-3">
                        <span className="text-xs whitespace-nowrap xs:block hidden whitespace-nowrap">
                          Generating Video
                        </span>
                        <div className="flex items-center justify-center w-5 h-5 relative">
                          <Lottie
                            animationData={loadingAnimationGreen}
                            autoplay={true}
                            loop={true}
                            className="w-6 h-6 absolute -mr-0.5 -mb-0.5"
                          />
                        </div>
                      </div>
                    )}

                    {/* Kebab Menu */}
                    <div className="hs-dropdown relative inline-flex">
                      <button
                        id="hs-dropdown-custom-icon-trigger"
                        onClick={(e) => e.preventDefault()}
                        type="button"
                        className="z-0 hs-dropdown-toggle flex justify-center items-center w-9 h-9 text-sm font-semibold rounded-lg text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
                      >
                        <svg
                          className="flex-none w-4 h-4 text-gray-600 dark:text-neutral-500"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <circle cx="12" cy="12" r="1" />
                          <circle cx="12" cy="5" r="1" />
                          <circle cx="12" cy="19" r="1" />
                        </svg>
                      </button>

                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedSession(session.name);
                          setSelectedSessionId(session.id);
                        }}
                        className="hs-dropdown-menu z-10 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg border border-gray-200 p-2 mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700"
                        aria-labelledby="hs-dropdown-custom-icon-trigger"
                      >
                        <button
                          className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-600 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700"
                          data-hs-overlay="#hs-vertically-centered-modal"
                        >
                          <IoTrashSharp className="w-4 h-4" />
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}

            {/* Page Count */}
            {totalPages > 1 && (
              <div className="px-6 py-4 hidden sm:flex justify-end">
                <div>
                  <p className="text-sm text-gray-600 dark:text-neutral-400">
                    Page{' '}
                    <span className="font-semibold text-gray-800 dark:text-neutral-200">
                      {currentPage}
                    </span>{' '}
                    of{' '}
                    <span className="font-semibold text-gray-800 dark:text-neutral-200">
                      {totalPages}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="max-w-4xl mx-auto">
          <div className="flex animate-pulse">
            <div className="w-full space-y-4">
              <ul className="h-[82px] space-y-3 flex flex-col justify-center">
                <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
                <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
              </ul>
              <ul className="h-[82px] space-y-3 flex flex-col justify-center">
                <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
                <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
              </ul>
              <ul className="h-[82px] space-y-3 flex flex-col justify-center">
                <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
                <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {totalPages > 1 && (
        <div className="sm:hidden sticky bottom-0 w-full bg-white dark:bg-slate-900 py-4 mt-2 flex justify-between">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
          >
            <svg
              className="shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m15 18-6-6 6-6" />
            </svg>
            Prev
          </button>

          <div className="flex items-center">
            <div>
              <p className="text-sm text-center text-gray-600 dark:text-neutral-400">
                Page{' '}
                <span className="font-semibold text-gray-800 dark:text-neutral-200">
                  {currentPage}
                </span>{' '}
                of{' '}
                <span className="font-semibold text-gray-800 dark:text-neutral-200">
                  {totalPages}
                </span>
              </p>
            </div>
          </div>

          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
          >
            Next
            <svg
              className="shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </button>
        </div>
      )}

      {/* Placeholder when no results and not loading */}
      {filteredSessions.length === 0 && sessions && (
        <div className="max-w-2xl mx-auto mt-16 text-center flex flex-col items-center">
          <SpilledInkIcon className="w-16 h-16" aria-hidden="true" />
          <div className="font-inknut text-lg flex flex-col">
            No recaps found
          </div>
          Create a new recap or adjust your filters.
        </div>
      )}

      <div
        id="hs-vertically-centered-modal"
        className="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
      >
        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
              <h3 className="font-bold text-gray-800 dark:text-white">
                Delete session?
              </h3>
              <button
                type="button"
                className="flex justify-center items-center w-6 h-6 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
                data-hs-overlay="#hs-vertically-centered-modal"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="flex-shrink-0 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            {/* <div className="flex bg-gray-200 p-4 justify-center text-center font-bold m-4 rounded-lg"> */}
            <div className="flex py-4 px-6 m-6 text-lg font-semibold text-gray-800 items-center justify-center text-center h-full rounded-md border border-gray-200 shadow-sm font-cardo">
              {selectedSession}
            </div>
            <div className="p-6 pt-0 overflow-y-auto">
              <p className=" text-sm text-gray-800 dark:text-neutral-400 text-center">
                This will permanently remove the session along with all
                associated transcriptions, summaries, audio files, and videos.
              </p>
            </div>
            <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
              <button
                type="button"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
                data-hs-overlay="#hs-vertically-centered-modal"
              >
                Cancel
              </button>
              {/* Replace recaps with chosen campaign when ready */}
              <button
                type="button"
                onClick={() =>
                  deleteSession(
                    auth.currentUser.uid,
                    'recaps',
                    selectedSessionId
                  )
                }
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black disabled:opacity-50 disabled:pointer-events-none"
                data-hs-overlay="#hs-vertically-centered-modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignPage;
