import React, { useState } from 'react';

const ContinueGenerationModal = ({ generateRecapFromSummary }) => {

  return (
    <div
    id="hs-modal-continue-generation"
    className="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
  >
    <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
      <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
        <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
          <h3 className="font-bold text-gray-800 dark:text-white">
            Continue generation?
          </h3>
          <button
            type="button"
            className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
            data-hs-overlay="#hs-modal-continue-generation"
          >
            <span className="sr-only">Close</span>
            <svg
              className="flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 6 6 18"></path>
              <path d="m6 6 12 12"></path>
            </svg>
          </button>
        </div>
        <div className="p-6 overflow-y-auto flex justify-center">
          <p className="max-w-xs text-sm text-gray-800 dark:text-neutral-400 text-center">
            {/* You will not be able to make changes to this recap after continuing. */}
            You won't be able to make changes to this recap after continuing.
          </p>
        </div>
        <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
          <button
            type="button"
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
            data-hs-overlay="#hs-modal-continue-generation"
          >
            Cancel
          </button>
          {/* Replace recaps with chosen campaign when ready */}
          <button
            type="button"
            onClick={generateRecapFromSummary}
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black disabled:opacity-50 disabled:pointer-events-none"
            data-hs-overlay="#hs-modal-continue-generation"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default ContinueGenerationModal;
