import React, { createContext, useState, useContext, useEffect } from 'react';

const SessionContext = createContext();

export const useSession = () => useContext(SessionContext);

export const SessionContextProvider = ({ children }) => {
  const [sessionsStatus, setSessionsStatus] = useState({}); // sessionID: { whisperProcessing: false, summaryProcessing: false }

  const updateSession = (sessionId, update) => {
    setSessionsStatus((prevSessions) => ({
      ...prevSessions,
      [sessionId]: { ...prevSessions[sessionId], ...update },
    }));
  };

  const value = {
    sessionsStatus,
    updateSession,
  };

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};
