import { GiVial, GiChemicalDrop, GiDrinkMe } from 'react-icons/gi';
import { FaDroplet, FaVial, FaFlask, FaBottleDroplet } from 'react-icons/fa6';
import { useState, useEffect } from 'react';

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth, storage, db } from '../firebaseConfig';
import { BiLogIn, BiLogOut } from 'react-icons/bi';
import {
  getDoc,
  doc,
  addDoc,
  setDoc,
  collection,
  onSnapshot,
} from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';

async function getStripeUrl(tier) {
  const endpoint = `https://us-central1-dungeon-copilot-6065c.cloudfunctions.net/create_stripe_session_${tier}`;

  console.log('Sending request to: ', endpoint);

  console.log('Current user ID: ', auth.currentUser.uid);

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userID: auth.currentUser.uid,
    }),
  });

  const result = await response.json();

  console.log('Response: ', result);

  if (response.ok) {
    console.log('Stripe payment URL generated: ', result.checkoutURL);
  } else {
    console.error('Error generating Stripe payment URL: ', result);
    // Update UI based on error
  }

  return result.checkoutURL;
}

function Pricing() {
  const [purchaseButtonStates, setPurchaseButtonStates] = useState({
    droplet: 'Purchase',
    vial: 'Purchase',
    inkwell: 'Purchase',
  });

  const [loadingStates, setLoadingStates] = useState({
    droplet: false,
    vial: false,
    inkwell: false,
  });

  const [pendingPurchaseTier, setPendingPurchaseTier] = useState(null);

  const handlePurchaseClick = async (tier) => {
    if (!auth.currentUser) {
      setPendingPurchaseTier(tier);
      // Open sign-up modal
      const modalElement = document.querySelector('#hs-modal-signup');
      if (window.HSOverlay && typeof window.HSOverlay.open === 'function') {
        window.HSOverlay.open(modalElement);
      } else {
        modalElement.classList.remove('hidden');
      }
      return;
    }
    // Proceed with purchase if the user is logged in
    proceedWithPurchase(tier);
  };

  const proceedWithPurchase = async (tier) => {
    try {
      setLoadingStates((prevStates) => ({ ...prevStates, [tier]: true }));
      const stripeUrl = await getStripeUrl(tier); // Fetch the Stripe URL

      if (stripeUrl) {
        window.location.href = stripeUrl;
      }
    } catch (error) {
      console.error('Error during purchase:', error);
    } finally {
      setLoadingStates((prevStates) => ({ ...prevStates, [tier]: false }));
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && pendingPurchaseTier) {
        proceedWithPurchase(pendingPurchaseTier);
        setPendingPurchaseTier(null);
      }
    });

    return () => unsubscribe();
  }, [pendingPurchaseTier]);

  return (
    <div>
      {/* <!-- Pricing --> */}

      {/* <!-- Grid --> */}
      <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-6 lg:items-center">
        {/* <!-- Card --> */}
        <div className="flex flex-col h-full justify-between border border-gray-200 text-center rounded-xl p-8 dark:border-gray-700">
          <div className="flex flex-col">
            <h4 className="font-medium text-lg text-gray-800 dark:text-gray-200 font-inknut">
              Free
            </h4>
            <FaDroplet className="mt-5 text-5xl text-gray-800 dark:text-gray-200 mx-auto" />
            <span className="mt-5 font-bold text-5xl text-gray-800 dark:text-gray-200">
              $0
            </span>
            <p className="mt-2 text-sm text-gray-500">
              Perfect for those dipping their quill for the first time
            </p>
            <ul className="mt-7 space-y-2.5 text-sm text-left">
              <li className="flex space-x-2">
                <svg
                  className="flex-shrink-0 mt-0.5 h-4 w-4 text-gray-600 dark:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
                <span className="text-gray-800 dark:text-gray-400">
                  1 free ink drop! (applied on account creation)
                </span>
              </li>
            </ul>
          </div>

          <div className="flex flex-col">
            <button
              type="button"
              className="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-gray-900 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              data-hs-overlay="#hs-modal-signup"
            >
              Sign up
            </button>
            <div className="flex justify-center mt-2 text-sm">
              <span className="text-gray-500 dark:text-gray-400">
                (1 recap)
              </span>
            </div>
          </div>
        </div>

        {/* <!-- Card --> */}
        <div className="flex flex-col relative h-full justify-between border border-gray-200 text-center rounded-xl p-8 dark:border-gray-700">
          <div className="flex flex-col">
            {/* <span className="absolute left-[50%] translate-x-[-50%] top-[-15px] whitespace-nowrap inline-flex items-center gap-1.5 py-1.5 px-3 rounded-lg text-xs uppercase font-semibold bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-white">
              Most popular
            </span> */}
            <h4 className="font-medium text-lg text-gray-800 dark:text-gray-200 font-inknut">
              Vial
            </h4>
            <FaVial className="mt-5 text-5xl text-gray-800 dark:text-gray-200 mx-auto" />
            <div className="mt-5 flex items-start justify-center">
              <span className="relative font-bold text-5xl text-gray-800 dark:text-gray-200">
                $20
                {/* <span className="absolute -top-4 -right-6 text-xl font-medium text-gray-500 line-through">
                  $20
                </span> */}
              </span>
            </div>

            <p className="mt-2 text-sm text-gray-500">Replenish your inkwell</p>
            <ul className="mt-7 space-y-2.5 text-sm text-left">
              <li className="flex space-x-2">
                <svg
                  className="flex-shrink-0 mt-0.5 h-4 w-4 text-gray-600 dark:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
                <span className="text-gray-800 dark:text-gray-400">
                  5 ink drops
                </span>
              </li>
            </ul>
          </div>

          <div className="flex flex-col">
            <button
              className={`mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent ${
                loadingStates.vial ? 'bg-gray-400' : 'bg-gray-100'
              } text-gray-800 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-gray-900 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
              onClick={() => handlePurchaseClick('vial')}
            >
              {loadingStates.vial ? (
                <div
                  className="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-black rounded-full"
                  role="status"
                  aria-label="loading"
                ></div>
              ) : (
                'Purchase'
              )}
            </button>
            <div className="flex justify-center mt-2 text-sm">
              <span className="text-gray-500 dark:text-gray-400">
                (5 recaps)
              </span>
            </div>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="flex flex-col relative h-full border-2 border-gray-600 text-center rounded-xl shadow-xl p-8 dark:border-gray-700">
          <div className="flex flex-col">
          <span className="absolute left-[50%] translate-x-[-50%] top-[-15px] whitespace-nowrap inline-flex items-center gap-1.5 py-1.5 px-3 rounded-lg text-xs uppercase font-semibold bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-white">
              Best Value
            </span>
            <h4 className="font-medium text-lg text-gray-800 dark:text-gray-200 font-inknut">
              Inkwell
            </h4>
            <FaFlask className="mt-5 text-5xl text-gray-800 dark:text-gray-200 mx-auto" />
            <div className="mt-5 flex items-start justify-center">
              <span className="relative font-bold text-5xl text-gray-800 dark:text-gray-200">
                $40
                {/* <span className="absolute -top-4 -right-6 text-xl font-medium text-gray-500 line-through">
                  $40
                </span> */}
              </span>
            </div>
            <p className="mt-2 text-sm text-gray-500">
              Enough ink to last an adventure
            </p>
            <ul className="mt-7 space-y-2.5 text-sm text-left">
              <li className="flex space-x-2">
                <svg
                  className="flex-shrink-0 mt-0.5 h-4 w-4 text-gray-600 dark:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
                <span className="text-gray-800 dark:text-gray-400">
                  10 ink drops
                </span>
              </li>
              <li className="flex space-x-2">
                <svg
                  className="flex-shrink-0 mt-0.5 h-4 w-4 text-gray-600 dark:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
                <span className="text-gray-800 dark:text-gray-400">
                  +2 free bonus ink drops
                </span>
              </li>
            </ul>
          </div>

          <div className="flex flex-col">
            <button
              className={`mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent ${
                loadingStates.inkwell ? 'bg-gray-400' : 'bg-gray-100'
              } text-gray-800 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-gray-900 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
              onClick={() => handlePurchaseClick('inkwell')}
            >
              {loadingStates.inkwell ? (
                <div
                  className="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-black rounded-full"
                  role="status"
                  aria-label="loading"
                ></div>
              ) : (
                'Purchase'
              )}
            </button>
            <div className="flex justify-center mt-2 text-sm">
              <span className="text-gray-500 dark:text-gray-400">
                (12 recaps)
              </span>
            </div>
          </div>
        </div>
        {/* <!-- End Card --> */}
      </div>
      {/* <!-- End Grid --> */}

      {/* <!-- End Pricing --> */}
    </div>
  );
}

export default Pricing;
