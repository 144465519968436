import React, { useEffect, useRef } from 'react';
import { auth, db } from '../firebaseConfig';
import { BiLogOut } from 'react-icons/bi';
import { doc, onSnapshot } from 'firebase/firestore';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { ReactComponent as Icon } from '../assets/Scrybe_Logo.svg';
import UploadSession from './UploadSession';
import SignUpModal from './SignUpModal';
import LogInModal from './LogInModal';

// Icons
import { GiScrollQuill, GiPointyHat } from 'react-icons/gi';
import { FaQuestionCircle } from 'react-icons/fa';
import { IoMail, IoMusicalNotes } from 'react-icons/io5';
import { FaDroplet, FaDollarSign } from 'react-icons/fa6';

const Navbar = () => {
  const [tokens, setTokens] = React.useState(0);
  const [currUser, setCurrUser] = React.useState(null);
  const [animateToken, setAnimateToken] = React.useState(false);

  let navigate = useNavigate();

  // Create refs for the collapse menu and toggle button
  const collapseMenuRef = useRef(null);
  const toggleButtonRef = useRef(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        setCurrUser(null);
      } else {
        setCurrUser(auth.currentUser);

        // Set up a real-time listener
        const unsubscribeDoc = onSnapshot(
          doc(db, `users/${auth.currentUser.uid}`),
          (doc) => {
            const userData = doc.data();
            if (userData && 'tokenBalance' in userData) {
              setTokens(userData.tokenBalance);
            } else {
              console.log(
                'Token balance not available or document does not exist'
              );
            }
          },
          (error) => {
            console.error('Error listening to user document:', error);
          }
        );

        return () => {
          unsubscribeDoc();
        };
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setAnimateToken(true);
    setTimeout(() => {
      setAnimateToken(false);
    }, 1000);
  }, [tokens]);

  const closeMenu = () => {
    if (window.HSCollapse && collapseMenuRef.current) {
      window.HSCollapse.hide(collapseMenuRef.current);
    }
  };

  const handleSignOut = async (e) => {
    auth.signOut();
    setTokens(0);
    navigate('/');
    closeMenu();
  };

  // Add the useEffect to handle clicks outside the collapse menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        collapseMenuRef.current &&
        toggleButtonRef.current &&
        !collapseMenuRef.current.contains(event.target) &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        // Hide the collapse menu
        if (window.HSCollapse) {
          window.HSCollapse.hide(collapseMenuRef.current);
        }
      }
    };

    // Add event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className="relative z-50 sticky top-0 shadow-lg w-full bg-white text-sm p-4 sm:p-6 lg:p-8 dark:bg-gray-800">
        <div className="flex items-center justify-between">
          {/* Left Side: Logo and Name */}
          <div className="flex items-center grow basis-0">
            <div className="flex items-center gap-x-2">
              <Link to="/" className="flex items-center gap-x-2">
                <Icon className="w-10 h-10" />
                <span className='font-inknut font-semibold hidden sm:block'>Scrybe</span>
              </Link>
              <Link
                to="/dev-log"
                className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 hover:bg-teal-200 text-teal-800 rounded-full dark:bg-blue-500/10 dark:text-blue-500"
              >
                <p>v1.2</p>
              </Link>
            </div>

            <div className="hidden lg:inline-block ml-4 mr-2">
              <div className="w-px h-4 bg-gray-300 dark:bg-neutral-700"></div>
            </div>
            {/* Nav Links (hidden on small screens) */}
            <nav className="hidden lg:flex items-center gap-x-1 flex-grow">
              <NavLink
                to="/faq"
                className={({ isActive }) =>
                  isActive
                    ? 'p-2 text-sm text-gray-800 bg-gray-100 rounded-lg whitespace-nowrap'
                    : 'p-2 text-sm text-gray-800 hover:bg-gray-100 rounded-lg whitespace-nowrap'
                }
              >
                FAQ
              </NavLink>
              <NavLink
                to="/pricing"
                className={({ isActive }) =>
                  isActive
                    ? 'p-2 text-sm text-gray-800 bg-gray-100 rounded-lg whitespace-nowrap'
                    : 'p-2 text-sm text-gray-800 hover:bg-gray-100 rounded-lg whitespace-nowrap'
                }
              >
                Pricing
              </NavLink>
              <NavLink
                to="/credit"
                className={({ isActive }) =>
                  isActive
                    ? 'p-2 text-sm text-gray-800 bg-gray-100 rounded-lg whitespace-nowrap'
                    : 'p-2 text-sm text-gray-800 hover:bg-gray-100 rounded-lg whitespace-nowrap'
                }
              >
                Music Credit
              </NavLink>
            </nav>
          </div>

          <UploadSession currUser={currUser} />

          {/* Right Side: Button Group */}
          <div className="flex items-center grow basis-0 justify-end">
            {currUser && (
              <>
                <NavLink
                  to="/campaigns/recaps"
                  className={({ isActive }) =>
                    isActive
                      ? 'hidden lg:flex p-2 text-sm text-gray-800 bg-gray-100 rounded-lg whitespace-nowrap'
                      : 'hidden lg:flex p-2 text-sm text-gray-800 hover:bg-gray-100 rounded-lg whitespace-nowrap'
                  }
                >
                  My Recaps
                </NavLink>
                <div className="hidden lg:inline-block ml-2 mr-4">
                  <div className="w-px h-4 bg-gray-300 dark:bg-neutral-700"></div>
                </div>
              </>
            )}

            <div className="flex items-center gap-x-4 justify-end">
              {currUser && (
                <Link
                  to="/pricing"
                  className="relative flex flex-col items-center font-medium text-primary-light hover:text-gray-400 text-center dark:text-gray-400 dark:hover:text-gray-500"
                >
                  {animateToken && (
                    <FaDroplet className="flex w-3 h-3 animate-ping absolute" />
                  )}
                  <FaDroplet className="flex w-3 h-3" />
                  <p className="font-medium text-xs leading-none text-center mx-auto">
                    {tokens}
                  </p>
                </Link>
              )}

              {/* Button Group (hidden on small screens) */}
              {!currUser ? (
                <div className="hidden sm:flex items-center gap-x-2">
                  <button
                    type="button"
                    className="py-2 px-3 flex items-center whitespace-nowrap gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                    data-hs-overlay="#hs-modal-login"
                  >
                    Log in
                  </button>
                  <button
                    type="button"
                    className="py-2 px-3 flex items-center whitespace-nowrap gap-x-2 text-sm font-medium rounded-lg bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black text-white shadow-sm"
                    data-hs-overlay="#hs-modal-signup"
                  >
                    Sign up
                  </button>
                </div>
              ) : (
                <>
                  <div className="hidden lg:inline-flex hs-dropdown relative">
                    <button
                      id="hs-dropdown-default"
                      type="button"
                      className="hs-dropdown-toggle py-2 px-3 sm:py-3 sm:px-4 inline-flex items-center gap-x-2 text-sm text-white font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800"
                    >
                      <GiPointyHat className="w-5 h-5" />
                      <svg
                        className="hs-dropdown-open:rotate-180 w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="m6 9 6 6 6-6" />
                      </svg>
                    </button>
                    <div
                      className="z-50 hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden w-[15rem] bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-gray-800"
                      aria-labelledby="hs-dropdown-with-header"
                    >
                      <div className="py-3 px-5 -m-2 bg-gray-100 rounded-t-lg dark:bg-gray-700">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Signed in as
                        </p>
                        <p className="truncate text-sm font-medium text-gray-800 dark:text-gray-300">
                          {currUser.email}
                        </p>
                      </div>
                      <div className="flex flex-col mt-2 py-2 gap-0.5 first:pt-0 last:pb-0">
                        {/* Contact */}

                        <NavLink
                          to="/contact"
                          className={({ isActive }) =>
                            isActive
                              ? 'p-2 flex items-center text-sm text-gray-800 bg-gray-100 rounded-lg'
                              : 'p-2 flex items-center text-sm text-gray-800 hover:bg-gray-100 rounded-lg'
                          }
                        >
                          <IoMail className="shrink-0 w-4 h-4 me-3" />
                          Contact
                        </NavLink>

                        <button
                          onClick={handleSignOut}
                          type="button"
                          className="p-2 flex items-center text-sm text-gray-800 hover:bg-gray-100 rounded-lg"
                        >
                          <BiLogOut className="w-4 h-4 me-3" />
                          Log Out
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Collapse Button (visible on small screens only) */}
              <div className="flex lg:hidden">
                <button
                  type="button"
                  ref={toggleButtonRef}
                  className="hs-collapse-toggle relative w-9 h-9 flex justify-center items-center font-medium text-[12px] rounded-lg border border-gray-200 text-gray-800 hover:bg-gray-100focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                  id="hs-header-base-collapse"
                  aria-expanded="false"
                  aria-controls="hs-header-base"
                  aria-label="Toggle navigation"
                  data-hs-collapse="#hs-header-base"
                >
                  <svg
                    className="hs-collapse-open:hidden w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="3" x2="21" y1="6" y2="6" />
                    <line x1="3" x2="21" y1="12" y2="12" />
                    <line x1="3" x2="21" y1="18" y2="18" />
                  </svg>
                  <svg
                    className="hs-collapse-open:block hidden w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                  <span className="sr-only">Toggle navigation</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Collapse content (visible on small screens only) */}
        <div
          id="hs-header-base"
          ref={collapseMenuRef}
          className="hs-collapse hidden lg:hidden overflow-hidden transition-all duration-300"
          aria-labelledby="hs-header-base-collapse"
        >
          {/* Collapse content */}
          <div className="overflow-hidden overflow-y-auto max-h-[75vh]">
            <div className="pt-4 pb-2 flex flex-col">
              {/* Nav Links */}
              <nav className="flex flex-col gap-0.5">
                {currUser && (
                  <NavLink
                    to="/campaigns/recaps"
                    onClick={closeMenu}
                    className={({ isActive }) =>
                      isActive
                        ? 'p-2 flex items-center text-sm text-gray-800 bg-gray-100 rounded-lg'
                        : 'p-2 flex items-center text-sm text-gray-800 hover:bg-gray-100 rounded-lg'
                    }
                  >
                    <GiScrollQuill className="shrink-0 w-4 h-4 me-3 block lg:hidden" />
                    My Recaps
                  </NavLink>
                )}
                <NavLink
                  to="/faq"
                  onClick={closeMenu}
                  className={({ isActive }) =>
                    isActive
                      ? 'p-2 flex items-center text-sm text-gray-800 bg-gray-100 rounded-lg'
                      : 'p-2 flex items-center text-sm text-gray-800 hover:bg-gray-100 rounded-lg'
                  }
                >
                  <FaQuestionCircle className="shrink-0 w-4 h-4 me-3 block lg:hidden" />
                  FAQ
                </NavLink>
                <NavLink
                  to="/pricing"
                  onClick={closeMenu}
                  className={({ isActive }) =>
                    isActive
                      ? 'p-2 flex items-center text-sm text-gray-800 bg-gray-100 rounded-lg'
                      : 'p-2 flex items-center text-sm text-gray-800 hover:bg-gray-100 rounded-lg'
                  }
                >
                  <FaDollarSign className="shrink-0 w-4 h-4 me-3 block lg:hidden" />
                  Pricing
                </NavLink>
                <NavLink
                  to="/credit"
                  onClick={closeMenu}
                  className={({ isActive }) =>
                    isActive
                      ? 'p-2 flex items-center text-sm text-gray-800 bg-gray-100 rounded-lg'
                      : 'p-2 flex items-center text-sm text-gray-800 hover:bg-gray-100 rounded-lg'
                  }
                >
                  <IoMusicalNotes className="shrink-0 w-4 h-4 me-3 block lg:hidden" />
                  Music Credit
                </NavLink>

                {/* Contact */}
                <NavLink
                  to="/contact"
                  onClick={closeMenu}
                  className={({ isActive }) =>
                    isActive
                      ? 'p-2 flex items-center text-sm text-gray-800 bg-gray-100 rounded-lg'
                      : 'p-2 flex items-center text-sm text-gray-800 hover:bg-gray-100 rounded-lg'
                  }
                >
                  <IoMail className="shrink-0 w-4 h-4 me-3 block lg:hidden" />
                  Contact
                </NavLink>
              </nav>

              {/* Button Group */}
              <div className="px-2 flex flex-wrap items-center gap-x-2">
                {!currUser ? (
                  // Wrap the horizontal bar and buttons inside a div that is hidden on 'sm' and larger screens
                  <div className="sm:hidden w-full">
                    <div className="my-2">
                      <div className="w-full h-px bg-gray-100 dark:bg-neutral-700"></div>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <button
                        type="button"
                        onClick={closeMenu}
                        className="py-2 px-3 flex items-center whitespace-nowrap gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                        data-hs-overlay="#hs-modal-login"
                      >
                        Log in
                      </button>
                      <button
                        type="button"
                        onClick={closeMenu}
                        className="py-2 px-3 flex items-center whitespace-nowrap gap-x-2 text-sm font-medium rounded-lg bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black text-white shadow-sm"
                        data-hs-overlay="#hs-modal-signup"
                      >
                        Sign up
                      </button>
                    </div>
                  </div>
                ) : (
                  // For logged-in users, always show the horizontal bar and content
                  <>
                    <div className="my-2 lg:hidden w-full">
                      <div className="w-full h-px bg-gray-100 dark:bg-neutral-700"></div>
                    </div>
                    <div className="flex justify-between w-full items-center space-x-4">
                      <div className="flex-1 min-w-0 dark:bg-gray-700">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Signed in as
                        </p>
                        <p className="truncate text-sm font-medium text-gray-800 dark:text-gray-300">
                          {currUser.email}
                        </p>
                      </div>
                      <button
                        onClick={(e) => {
                          handleSignOut(e);
                          closeMenu();
                        }}
                        type="button"
                        className="py-2 px-3 flex items-center whitespace-nowrap gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                      >
                        <BiLogOut className="w-4 h-4" />
                        Log Out
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* End Collapse content */}
      </header>
      {/* Include the SignUpModal and LogInModal components */}
      <SignUpModal />
      <LogInModal />
    </>
  );
};

export default Navbar;
