import React, { useState } from 'react';
import {
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import { auth, db } from '../firebaseConfig';
import {
  doc,
  setDoc,
  getDoc,
  collection,
  addDoc,
  Timestamp,
} from 'firebase/firestore';

const SignUpModal = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // New state for confirm password
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  async function getDemoSessionData(sample_name) {
    // Get session info from firestore database /sample_data/sample_1
    const sessionRef = doc(db, 'sample_data', sample_name);
    const sessionDoc = await getDoc(sessionRef);
    const sessionData = sessionDoc.data();
    return sessionData;
  }

  // Function to create a new session with hardcoded values
  async function addDemoSession(
    session_name,
    transcription,
    summary,
    narrationStoragePath,
    narrator_name,
    backgroundMusicName,
    narratedVideoStoragePath
  ) {
    try {
      // Ensure the user is authenticated
      if (!auth.currentUser) {
        console.error('User is not authenticated');
        return;
      }

      // Hardcoded session data
      const newSession = {
        name: session_name,
        transcription: transcription,
        timeCreated: Timestamp.now(),
        recaps: {
          recap1: {
            summary: summary,
            narrationStoragePath: narrationStoragePath,
            narrator: {
              name: narrator_name,
            },
            backgroundMusicName: backgroundMusicName,
            narratedVideoStoragePath: narratedVideoStoragePath,
          },
        },
      };

      // Reference to the sessions collection
      const sessionsRef = collection(
        db,
        `users/${auth.currentUser.uid}/campaigns/recaps/sessions`
      );

      // Add the new session document to Firestore
      const docRef = await addDoc(sessionsRef, newSession);
      console.log('New session created with ID:', docRef.id);
    } catch (error) {
      console.error('Error creating new session:', error);
    }
  }

  async function addAllDemoSessions() {
    const sessionData = await getDemoSessionData('sample_1');
    await addDemoSession(
      sessionData.session_name,
      sessionData.transcription,
      sessionData.summary,
      sessionData.narrationStoragePath,
      sessionData.narrator_name,
      sessionData.backgroundMusicName,
      sessionData.narratedVideoStoragePath
    );
    
    const sessionData2 = await getDemoSessionData('sample_2');
    await addDemoSession(
      sessionData2.session_name,
      sessionData2.transcription,
      sessionData2.summary,
      sessionData2.narrationStoragePath,
      sessionData2.narrator_name,
      sessionData2.backgroundMusicName,
      sessionData2.narratedVideoStoragePath
    );
  };

  const closeModal = () => {
    const modalElement = document.querySelector('#hs-modal-signup');
    if (window.HSOverlay && typeof window.HSOverlay.close === 'function') {
      window.HSOverlay.close(modalElement);
    } else {
      modalElement.classList.add('hidden');
    }

    // Clear form
    setEmail('');
    setPassword('');
    setConfirmPassword(''); // Clear confirm password
    setError('');
  };

  const handleGoogle = async (e) => {
    e.preventDefault();
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      const userRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(userRef);
  
      if (!docSnap.exists()) {
        // New user: Create user document and add demo sessions
        await setDoc(
          userRef,
          {
            tokenBalance: 1,
            email: user.email,
            createdAt: new Date(),
          },
          { merge: true }
        );
  
        // Add demo sessions only for new users
        await addAllDemoSessions();
      }
  
      closeModal();
    } catch (error) {
      console.log(error);
      // Handle different Google sign-in errors
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          // setError('Sign-in was cancelled. Please try again.');
          break;
        case 'auth/account-exists-with-different-credential':
          setError(
            'An account already exists with the same email but different sign-in credentials.'
          );
          break;
        default:
          setError(
            'Something went wrong with Google sign-in. Please try again.'
          );
      }
    }
  };

  const handleEmailSignUp = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setIsLoading(true);

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setIsLoading(false);
      return;
    }

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Send verification email
      await sendEmailVerification(user);
      setMessage('Verification email sent. Please check your inbox.');

      // Store user data in Firestore
      const userRef = doc(db, 'users', user.uid);
      await setDoc(
        userRef,
        {
          tokenBalance: 1,
          email: user.email,
          createdAt: new Date(),
        },
        { merge: true }
      );

      // Call addDemoSession after user sign-up
      await addAllDemoSessions();

      // Sign out the user immediately after sign-up
      await auth.signOut();

      // Clear form fields
      setEmail('');
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.log(error);
      // Handle different sign-up errors
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError(
            'This email is already in use. Did you mean to log in instead?'
          );
          break;
        case 'auth/missing-email':
          setError('The email address is missing. Please provide an email.');
          break;
        case 'auth/invalid-email':
          setError(
            'The email address is invalid. Please provide a valid email.'
          );
          break;
        case 'auth/missing-password':
          setError('The password is invalid. Please provide a valid password.');
          break;
        case 'auth/weak-password':
          setError(
            'The password is too short. Please use a stronger password.'
          );
          break;
        default:
          setError('An error occurred during sign-up. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {/* Modal */}
      <div
        id="hs-modal-signup"
        className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
        tabIndex="-1"
        aria-labelledby="hs-modal-signup-label"
      >
        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div className="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
            <div className="p-4 sm:p-7">
              <div className="text-center">
                <h3
                  id="hs-modal-signup-label"
                  className="block text-2xl font-bold text-gray-800 font-inknut"
                >
                  Sign up
                </h3>
                <p className="mt-2 text-sm text-gray-600 gap-x-1 flex items-center justify-center">
                  Already have an account?
                  <a
                    className="text-gray-800 decoration-2 hover:underline focus:outline-none focus:underline font-medium"
                    href="#"
                    data-hs-overlay="#hs-modal-login"
                  >
                    Log in here
                  </a>
                </p>
              </div>
              <div className="mt-5">
                {error && (
                  <p className="text-red-600 text-sm mb-4 text-center">
                    {error}
                  </p>
                )}
                {message && (
                  <p className="text-green-600 text-sm mb-4 text-center">
                    {message}
                  </p>
                )}
                <a
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
                  href="#"
                  onClick={handleGoogle}
                >
                  <svg
                    className="w-4 h-auto"
                    width="46"
                    height="47"
                    viewBox="0 0 46 47"
                    fill="none"
                  >
                    <path
                      d="M46 24.0287C46 22.09 45.8533 20.68 45.5013 19.2112H23.4694V27.9356H36.4069C36.1429 30.1094 34.7347 33.37 31.5957 35.5731L31.5663 35.8669L38.5191 41.2719L38.9885 41.3306C43.4477 37.2181 46 31.1669 46 24.0287Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M23.4694 47C29.8061 47 35.1161 44.9144 39.0179 41.3012L31.625 35.5437C29.6301 36.9244 26.9898 37.8937 23.4987 37.8937C17.2793 37.8937 12.0281 33.7812 10.1505 28.1412L9.88649 28.1706L2.61097 33.7812L2.52296 34.0456C6.36608 41.7125 14.287 47 23.4694 47Z"
                      fill="#34A853"
                    />
                    <path
                      d="M10.1212 28.1413C9.62245 26.6725 9.32908 25.1156 9.32908 23.5C9.32908 21.8844 9.62245 20.3275 10.0918 18.8588V18.5356L2.75765 12.8369L2.52296 12.9544C0.909439 16.1269 0 19.7106 0 23.5C0 27.2894 0.909439 30.8731 2.49362 34.0456L10.1212 28.1413Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M23.4694 9.07688C27.8699 9.07688 30.8622 10.9863 32.5344 12.5725L39.1645 6.11C35.0867 2.32063 29.8061 0 23.4694 0C14.287 0 6.36607 5.2875 2.49362 12.9544L10.0918 18.8588C11.9987 13.1894 17.25 9.07688 23.4694 9.07688Z"
                      fill="#EB4335"
                    />
                  </svg>
                  Sign up with Google
                </a>
                <div className="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-1 before:border-t before:border-gray-200 before:mr-6 after:flex-1 after:border-t after:border-gray-200 after:ml-6">
                  Or
                </div>
                {/* Form */}
                <form onSubmit={handleEmailSignUp}>
                  <div className="grid gap-y-4">
                    {/* Email */}
                    <div>
                      <label htmlFor="email" className="block text-sm mb-2">
                        Email address
                      </label>
                      <div className="relative">
                        <input
                          id="signup-email"
                          name="email"
                          className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          autoComplete='username'
                        />
                      </div>
                    </div>
                    {/* Password */}
                    <div>
                      <label htmlFor="password" className="block text-sm mb-2">
                        Password
                      </label>
                      <div className="relative">
                        <input
                          type="password"
                          id="signup-password"
                          name="password"
                          className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          autoComplete='new-password'
                        />
                      </div>
                    </div>
                    {/* Confirm Password */}
                    <div>
                      <label
                        htmlFor="confirmPassword"
                        className="block text-sm mb-2"
                      >
                        Confirm Password
                      </label>
                      <div className="relative">
                        <input
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          autoComplete='new-password'
                        />
                      </div>
                    </div>
                    {/* Submit Button */}
                    <button
                      type="submit"
                      className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black text-white focus:outline-none focus:bg-gray-700"
                    >
                      {isLoading ? (
                        <div
                          className="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-white rounded-full"
                          role="status"
                          aria-label="loading"
                        ></div>
                      ) : (
                        'Sign up'
                      )}
                    </button>
                    {/* Terms of Service */}
                    <p className="text-center text-xs text-gray-500 w-56 xs:w-full mx-auto">
                      By creating an account, you agree to Scrybe's{' '}
                      <a
                        href="https://www.scrybequill.com/terms-of-service"
                        className="font-medium text-gray-800 hover:underline"
                      >
                        Terms of Service
                      </a>
                      .
                    </p>
                  </div>
                </form>
                {/* End Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal */}
    </div>
  );
};

export default SignUpModal;
