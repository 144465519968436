import { useState, useEffect } from 'react';

import { auth, db } from '../firebaseConfig'; // Adjust the import based on your file structure
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import {
  doc,
  setDoc,
  deleteDoc,
  collection,
  getDocs,
  addDoc,
  Timestamp,
  orderBy,
  query,
} from 'firebase/firestore';
import BreadCrumb from '../components/BreadCrumb';

import { VscKebabVertical } from 'react-icons/vsc';
import { GoNoEntry, GoPencil } from 'react-icons/go';

import { Link } from 'react-router-dom';

function Campaigns() {
  window.scrollTo(0, 0);

  const [campaigns, setCampaigns] = useState([]);
  const [campaignName, setCampaignName] = useState('');
  const [campaignDescription, setCampaignDescription] = useState('');
  const [campaignImage, setCampaignImage] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const fetchCampaigns = async () => {
          const campaignsCollection = collection(
            db,
            `users/${user.uid}/campaigns`
          );
          const campaignsQuery = query(
            campaignsCollection,
            orderBy('timeCreated', 'desc')
          ); // order by timeCreated in descending order
          const campaignsSnapshot = await getDocs(campaignsQuery);
          const campaignsData = campaignsSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setCampaigns(campaignsData);
        };

        fetchCampaigns();
      } else {
        // User is signed out
        setCampaigns([]); // Clear the campaigns
      }
    });
    return () => unsubscribe();
  }, []);

  const handleCreateCampaign = async (e) => {
    e.preventDefault();

    // Add to Firestore
    const newCampaign = {
      name: campaignName,
      image: campaignImage,
      timeCreated: Timestamp.now(),
    };
    const docRef = await addDoc(
      collection(db, `users/${auth.currentUser.uid}/campaigns`),
      newCampaign
    );
    console.log('Document written with ID: ', docRef.id);

    // Clear the form
    setCampaignName('');
    setCampaignDescription('');
    setCampaignImage(null);

    // Add id to the new campaign
    newCampaign.id = docRef.id;

    // Update the local state
    setCampaigns([newCampaign, ...campaigns]);
  };

  const handleDelete = async (campaignId) => {
    try {
      await deleteDoc(
        doc(db, `users/${auth.currentUser.uid}/campaigns`, campaignId)
      );
      // Remove campaign from local state
      const updatedCampaigns = campaigns.filter(
        (campaign) => campaign.id !== campaignId
      );
      setCampaigns(updatedCampaigns);
    } catch (error) {
      console.error('Error deleting campaign: ', error);
    }
  };

  return (
    <div className="max-w-7xl p-8 mx-auto">
      <div className="flex flex-col mb-4 sm:flex-row justify-between sm:items-start">
        {/* Breadcrumb */}
        <div className="mb-4 sm:mb-0">
          <BreadCrumb
            breadcrumbs={[
              { name: 'Home', link: '/' },
              { name: 'My Campaigns', link: '/campaigns' },
            ]}
          />
        </div>

        <button
          type="button"
          className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2 border-gray-900 font-semibold text-gray-800 hover:text-white hover:bg-gray-800 hover:border-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 transition-all text-sm dark:hover:bg-gray-900 dark:border-gray-900 dark:hover:border-gray-900 dark:text-white dark:focus:ring-gray-900 dark:focus:ring-offset-gray-800"
          data-hs-overlay="#hs-cookies"
        >
          + New Campaign
        </button>
      </div>

      {/* Campaigns */}
      <div className="mx-auto">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Dynamically rendered campaigns */}
          {campaigns.map((campaign) => (
            <div
              key={campaign.id}
              className="group flex flex-col h-full bg-white border border-gray-200 shadow-sm rounded-xl transition ease-in-out hover:scale-101 hover:shadow-xl duration-75 dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]"
            >
              <Link to={`/campaigns/${campaign.id}`}>
                <div className="aspect-w-16 aspect-h-9 relative bg-gray-500 rounded-t-xl">
                  <img
                    className="absolute inset-0 w-full h-full rounded-t-xl object-cover"
                    src={campaign.image}
                    onError={(e) => {
                      e.target.classList.add('hidden');
                    }}
                  />
                </div>

                <div className="p-4 flex items-center justify-between">
                  <div className="flex-1"></div>
                  <h3 className="text-xl font-semibold text-gray-800 line-clamp-1 dark:text-gray-300 dark:hover:text-white flex-2 text-center">
                    {campaign.name}
                  </h3>
                  <div className="flex-1 flex justify-end">
                    {/* <VscKebabVertical /> */}

                    <div className="hs-dropdown relative inline-flex">
                      <button
                        onClick={(e) => e.preventDefault()}
                        id="hs-dropdown-basic"
                        type="button"
                        className="hs-dropdown-toggle z-100 py-3 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg bg-white text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      >
                        <svg
                          className="flex-shrink-0 w-4 h-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <circle cx="12" cy="5" r="1" />
                          <circle cx="12" cy="12" r="1" />
                          <circle cx="12" cy="19" r="1" />
                        </svg>
                      </button>

                      <div
                        className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden z-10 mt-2 bg-white shadow-md rounded-lg p-2 flex items-center gap-x-1 dark:bg-gray-800 dark:border dark:border-gray-700 dark:divide-gray-700"
                        aria-labelledby="hs-dropdown-basic"
                      >
                        <a
                          className="py-2 px-2 rounded-lg text-sm text-rose-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
                          href="#"
                        >
                          <GoNoEntry />
                        </a>
                        <a
                          className="py-2 px-2 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
                          href="#"
                        >
                          <GoPencil />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              {/* <div className="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 dark:border-gray-700 dark:divide-gray-700">
                <button onClick={() => handleDelete(campaign.id)}
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-2 rounded-bl-xl font-medium bg-rose-200 text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-black transition-all text-sm sm:p-4 dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800 focus:z-10 relative">
                  Delete
                </button>
                <button
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-2 font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-black transition-all text-sm sm:p-4 dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800 focus:z-10 relative">
                  Edit
                </button>
                <button
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-2 rounded-br-xl font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-black transition-all text-sm sm:p-4 dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800 focus:z-10 relative">
                  View
                </button>

              </div> */}
            </div>
          ))}

          {/* New Campaign Modal Form*/}
          <div
            id="hs-cookies"
            className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto"
          >
            <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
              <div className="relative flex flex-col bg-white shadow-lg rounded-xl dark:bg-gray-800">
                <form onSubmit={handleCreateCampaign}>
                  <div className="absolute top-2 right-2">
                    <button
                      type="button"
                      className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                      data-hs-overlay="#hs-cookies"
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        className="w-3.5 h-3.5"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="p-4 sm:p-14 text-center overflow-y-auto ">
                    <form onSubmit={handleCreateCampaign}>
                      <div className="bg-white rounded-xl dark:bg-slate-900 border border-gray-200 shadow-lg">
                        <div className="pt-0 p-4 sm:pt-0 sm:p-7">
                          <div className="space-y-4 sm:space-y-6">
                            <div className="space-y-2">
                              <label
                                htmlFor="af-submit-app-project-name"
                                className="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-gray-200"
                              >
                                Campaign name
                              </label>

                              <input
                                id="af-submit-app-project-name"
                                type="text"
                                placeholder="Enter campaign name"
                                value={campaignName}
                                onChange={(e) =>
                                  setCampaignName(e.target.value)
                                }
                                className="py-2 px-3 pr-11 block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                              />
                            </div>

                            <div className="space-y-2">
                              <label
                                htmlFor="af-submit-app-project-name"
                                className="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-gray-200"
                              >
                                Image URL
                              </label>

                              <input
                                id="af-submit-app-project-name"
                                type="text"
                                placeholder="Enter image URL"
                                value={campaignImage}
                                onChange={(e) =>
                                  setCampaignImage(e.target.value)
                                }
                                className="py-2 px-3 pr-11 block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                              />
                            </div>

                            {/* <div className="space-y-2">
                              <label htmlFor="af-submit-app-description" className="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-gray-200">
                                Description
                              </label>

                              <textarea
                                id="af-submit-app-description"
                                placeholder="A detailed summary will better explain your products to the audiences. Our users will see this in your dedicated product page."
                                value={campaignDescription}
                                onChange={(e) => setCampaignDescription(e.target.value)}
                                className="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400" rows="4"></textarea>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="flex items-center">
                    <button
                      type="button"
                      className="p-4 w-full inline-flex justify-center items-center gap-2 rounded-bl-xl bg-gray-100 border border-transparent font-semibold text-gray-800 hover:text-black focus:outline-none focus:ring-2 ring-offset-white focus:ring-black focus:ring-offset-2 transition-all text-sm dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-600 dark:text-white dark:focus:ring-offset-gray-800"
                      data-hs-overlay="#hs-cookies"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="p-4 w-full inline-flex justify-center items-center gap-2 rounded-br-xl border border-transparent font-semibold bg-black text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                      data-hs-overlay="#hs-cookies"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="py-6 grid gap-3 ">
          <div className="inline-flex gap-x-2 justify-end">
            <button
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            >
              <svg
                className="flex-shrink-0 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m15 18-6-6 6-6" />
              </svg>
              Prev
            </button>

            <button
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            >
              Next
              <svg
                className="flex-shrink-0 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaigns;
