import React, { useState, useEffect, useRef } from 'react';
import { auth, storage, db } from '../firebaseConfig';
import { useParams } from 'react-router-dom';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, getBlob } from 'firebase/storage';
import TextBox from '../components/TextBox';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import ContinueGenerationModal from '../components/ContinueGenerationModal';

import moment from 'moment';

import { IoMusicalNotes } from 'react-icons/io5';
import { HiDownload } from 'react-icons/hi';

import Lottie from 'lottie-react';
import loadingAnimationBlue from '../assets/loading_lottie_blue.json';
import loadingAnimationRed from '../assets/loading_lottie_red.json';
import loadingAnimationGreen from '../assets/loading_lottie_green.json';
import loadingAnimationYellow from '../assets/loading_lottie_yellow.json';

import { ReactComponent as ScrybeLogo } from '../assets/Scrybe_Logo.svg';
import { ReactComponent as WizardMicrophoneIcon } from '../assets/WizardMicrophoneIcon.svg';

import EditableTextBox from '../components/EditableTextBox';
import CharacterCount from '@tiptap/extension-character-count';

async function generateDownloadLink(filePath) {
  // Create a reference to the file in Storage
  const fileRef = ref(storage, filePath);

  // Generate the download URL
  return getDownloadURL(fileRef)
    .then((url) => {
      return url;
    })
    .catch((error) => {
      // Handle any errors
      console.error('Error generating download link: ', error);
    });
}

function SessionPage() {
  const { campaignId } = useParams();
  const { sessionId } = useParams();

  const [sessionData, setSessionData] = useState({});

  const [currentRecap, setCurrentRecap] = useState(1);

  const [narrationAudioURL, setNarrationAudioURL] = useState('');
  const [narrationVideoURL, setNarrationVideoURL] = useState('');

  const [narrationAudioFilePath, setNarrationAudioFilePath] = useState('');
  const [narrationVideoFilePath, setNarrationVideoFilePath] = useState('');

  const [sessionName, setSessionName] = useState(null);
  const [sessionDate, setSessionDate] = useState(null);
  const [transcription, setTranscription] = useState(null);

  const [summary, setSummary] = useState(null);
  const [originalSummary, setOriginalSummary] = useState(null);

  const [manualEdit, setManualEdit] = useState(false);

  const [narrator, setNarrator] = useState(null);
  const [backgroundMusicName, setBackgroundMusicName] = useState(null);
  const [backgroundMusicPath, setBackgroundMusic] = useState(null);

  // -------------- EDITOR --------------
  const [editorContent, setEditorContent] = useState('');
  const [charCount, setCharCount] = useState(0); // New state variable to track character count
  const maxCharacters = 3000; // Maximum allowed characters
  const charactersRemaining = maxCharacters - charCount; // Calculate remaining characters

  const editor = useEditor({
    extensions: [StarterKit, CharacterCount],
    onUpdate({ editor }) {
      setEditorContent(editor.getText());
      setCharCount(editor.storage.characterCount.characters());
    },
    editable: manualEdit, // Set initial editable state
  });

  function textToHTML(text) {
    if (!text) {
      return '';
    }
    return text
      .split('\n')
      .map((line) => `<p>${line}</p>`)
      .join('');
  }

  useEffect(() => {
    if (editor) {
      // Swap new lines for <p> tags
      const formattedSummary = textToHTML(summary);
      editor.commands.setContent(formattedSummary);
    }
  }, [summary]);

  useEffect(() => {
    if (editor) {
      editor.setEditable(manualEdit);
    }
  }, [manualEdit, editor]);

  // Usestate for getting transcription from firebase
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Reference to the session document
        const sessionDocRef = doc(
          db,
          `users/${auth.currentUser.uid}/campaigns/${campaignId}/sessions/${sessionId}`
        );

        // Attaching the listener with onSnapshot
        const unsubscribeDoc = onSnapshot(
          sessionDocRef,
          async (doc) => {
            if (doc.exists()) {
              const tempSessionData = doc.data();
              setSessionName(tempSessionData.name);
              setSessionDate(
                moment(tempSessionData.timeCreated.toDate()).format(
                  'MMMM Do, YYYY'
                )
              );
              setTranscription(tempSessionData.transcription);
              setSummary(tempSessionData.recaps.recap1.summary);

              setEditorContent(tempSessionData.recaps.recap1.summary);

              setOriginalSummary(tempSessionData.recaps.recap1.summary);
              setNarrator(tempSessionData.recaps.recap1.narrator);
              setBackgroundMusicName(
                tempSessionData.recaps.recap1.backgroundMusicName
              );
              setBackgroundMusic(
                tempSessionData.recaps.recap1.backgroundMusicPath
              );

              setManualEdit(tempSessionData.recaps.recap1.manualEdit);

              // If there is a narration, get the download link
              if (tempSessionData.recaps.recap1.narrationStoragePath) {
                setNarrationAudioFilePath(
                  tempSessionData.recaps.recap1.narrationStoragePath
                );
                setNarrationAudioURL(
                  await generateDownloadLink(
                    tempSessionData.recaps.recap1.narrationStoragePath
                  )
                );
              }

              if (tempSessionData.recaps.recap1.narratedVideoStoragePath) {
                setNarrationVideoFilePath(
                  tempSessionData.recaps.recap1.narratedVideoStoragePath
                );
                setNarrationVideoURL(
                  await generateDownloadLink(
                    tempSessionData.recaps.recap1.narratedVideoStoragePath
                  )
                );
              }

              setSessionData(doc.data());
            } else {
              // Handle the case where the document does not exist
              console.log('No such document!');
            }
          },
          (error) => {
            console.error('Error listening to session updates: ', error);
          }
        );

        // Return the cleanup function
        return () => {
          unsubscribeDoc(); // Unsubscribe from document changes
        };
      } else {
        // User is signed out
        setTranscription([]);
        setSummary([]);
        setNarrator(null);
      }
    });

    // Cleanup the auth state listener on component unmount
    return () => unsubscribe();
  }, []);

  const generateRecapFromSummary = async () => {
    // Set the manual edit to false in the database
    const sessionDocRef = doc(
      db,
      `users/${auth.currentUser.uid}/campaigns/${campaignId}/sessions/${sessionId}`
    );

    await updateDoc(sessionDocRef, {
      [`recaps.recap${currentRecap}.manualEdit`]: false,
    });

    const endpoint =
      'https://us-central1-dungeon-copilot-6065c.cloudfunctions.net/generate_recap_from_summary';

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userID: auth.currentUser.uid,
        campaignID: campaignId,
        sessionID: sessionId,
      }),
    });
  };

  const nextRecap = () => {
    setCurrentRecap(currentRecap + 1);

    setSummary(sessionData.recaps[`recap${currentRecap + 1}`].summary);

    setNarrationAudioURL(
      generateDownloadLink(
        sessionData.recaps[`recap${currentRecap + 1}`].narrationStoragePath
      )
    );
  };

  const handleSaveChanges = async () => {
    // Check if editorContent exceeds the character limit
    if (editorContent.length > maxCharacters) {
      alert(`Content exceeds the maximum limit of ${maxCharacters} characters.`);
      return; // Exit the function without saving
    }
  
    // Proceed with saving if under the limit
    const sessionDocRef = doc(
      db,
      `users/${auth.currentUser.uid}/campaigns/${campaignId}/sessions/${sessionId}`
    );
  
    editor.commands.setContent(editorContent.trim());
  
    // Update the summary in the database
    await updateDoc(sessionDocRef, {
      [`recaps.recap${currentRecap}.summary`]: editorContent.trim(),
    });
  
    // Update the state of the original summary
    setOriginalSummary(editorContent.trim());
  
    // Hide the warning message
    setShowWarning(false);
  };
  

  const handleDiscardChanges = () => {
    const formattedSummary = textToHTML(originalSummary);
    editor.commands.setContent(formattedSummary);
    setEditorContent(originalSummary);

    // Hide the warning message
    setShowWarning(false);
  };

  const [showWarning, setShowWarning] = useState(false);

  const downloadFile = async (filePath, filename) => {
    try {
      const fileRef = ref(storage, filePath);
      const blob = await getBlob(fileRef);

      const blobURL = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobURL;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobURL);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  function sanitizeSessionName(name) {
    if (!name) return '';
    return name
      .replace(/[\\/:*?"<>|\x00-\x1F]/g, '') // Replace invalid characters
      .trim() // Trim leading/trailing spaces
      .replace(/[\. ]+$/, ''); // Remove trailing periods or spaces
  }

  return (
    <div>
      <div className="max-w-7xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
        <div className="max-w-2xl mx-auto text-center mb-10 md:mb-14">
          <h1 className="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut max-w-md mx-auto">
            {sessionName}
          </h1>
          <p className="mt-1 text-gray-600 dark:text-gray-400 max-w-xs mx-auto">
            {sessionDate}
          </p>

          <div className="mt-1">
            {/* Status Spinner */}
            {sessionData?.transcription === null && (
              <div className="flex items-center justify-center gap-1">
                <span className="text-xs whitespace-nowrap">Transcribing</span>
                <Lottie
                  animationData={loadingAnimationBlue}
                  autoplay={true}
                  loop={true}
                  className="h-6 w-6 -mb-0.5"
                />
              </div>
            )}

            {sessionData?.transcription !== null &&
              sessionData?.recaps?.recap1?.summary === null && (
                <div className="flex items-center justify-center gap-1">
                  <span className="text-xs whitespace-nowrap">Summarizing</span>
                  <Lottie
                    animationData={loadingAnimationRed}
                    autoplay={true}
                    loop={true}
                    className="h-6 w-6 -mb-0.5"
                  />
                </div>
              )}

            {sessionData?.transcription !== null &&
              sessionData?.recaps?.recap1?.summary !== null &&
              manualEdit && (
                <div className="flex items-center justify-center gap-x-1">
                  <span className="text-xs whitespace-nowrap">
                    Review Recap
                  </span>
                  <ScrybeLogo className="w-5 h-5" />
                </div>
              )}

            {sessionData?.transcription !== null &&
              sessionData?.recaps?.recap1?.summary !== null &&
              sessionData?.recaps?.recap1?.narrationStoragePath === null &&
              !manualEdit && (
                <div className="flex items-center justify-center gap-1">
                  <span className="text-xs whitespace-nowrap">Narrating</span>
                  <Lottie
                    animationData={loadingAnimationYellow}
                    autoplay={true}
                    loop={true}
                    className="h-6 w-6 -mb-0.5"
                  />
                </div>
              )}

            {sessionData?.transcription !== null &&
              sessionData?.recaps?.recap1?.summary !== null &&
              sessionData?.recaps?.recap1?.narrationStoragePath !== null &&
              sessionData?.recaps?.recap1?.narratedVideoStoragePath ===
                null && (
                <div className="flex items-center justify-center gap-1">
                  <span className="text-xs whitespace-nowrap">
                    Generating Video
                  </span>
                  <Lottie
                    animationData={loadingAnimationGreen}
                    autoplay={true}
                    loop={true}
                    className="h-6 w-6 -mb-0.5"
                  />
                </div>
              )}
          </div>
        </div>

        <div>
          <div className="justify-center pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut">
            <h2 className="text-xl font-semibold text-center">Transcription</h2>
          </div>
          {transcription ? (
            <TextBox
              // title="Transcription"
              content={transcription}
            />
          ) : (
            <ul className="space-y-3 animate-pulse">
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
            </ul>
          )}
        </div>

        <div>
          <div className="justify-center pt-16 pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut">
            <h2 className="text-xl font-semibold text-center">Text Recap</h2>
          </div>
          {summary ? (
            <>
                       <div className="dots-corners text-sm border border-y-gray-200 w-full flex flex-col bg-white border-x-2 border-black p-4 ">
              <EditorContent
                editor={editor}
                className=""
                spellCheck="false"
              />
            </div>

            {editorContent !== originalSummary && (
              <>
                <div className="mt-3 flex justify-between gap-4 flex-col xs:flex-row">
                  {showWarning && (
                    <div className="flex flex-col text-sm text-center items-center xs:items-start xs:text-left w-full">
                      <p
                        className={`text-sm text-center ${
                          charactersRemaining >= 0
                            ? 'text-gray-500'
                            : 'text-red-500'
                        }`}
                      >
                        {charactersRemaining >= 0
                          ? `${charactersRemaining} characters remaining`
                          : `${Math.abs(charactersRemaining)} characters over limit`}
                      </p>
                      <p className="text-red-500">
                        Please save or discard your changes before continuing.
                      </p>
                    </div>
                  )}
                  {!showWarning && (
                    <p
                      className={`text-sm text-center ${
                        charactersRemaining >= 0
                          ? 'text-gray-500'
                          : 'text-red-500'
                      }`}
                    >
                      {charactersRemaining >= 0
                        ? `${charactersRemaining} characters remaining`
                        : `${Math.abs(charactersRemaining)} characters over limit`}
                    </p>
                  )}

                  <div className="flex gap-2 flex-col xs:flex-row">
                    <button
                      type="button"
                      onClick={handleDiscardChanges}
                      className="py-2 px-3 max-h-10 whitespace-nowrap inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    >
                      Discard changes
                    </button>
                    <button
                      id="session-submit-btn"
                      onClick={handleSaveChanges}
                      type="submit"
                      className="py-2 px-3 max-h-10 inline-flex items-center gap-x-2 text-sm justify-center font-semibold rounded-lg border border-transparent text-white bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      disabled={charCount > maxCharacters} // Disable if over limit
                    >
                      Save
                    </button>
                  </div>
                </div>
              </>
            )}

              {manualEdit && (
                <div className="flex justify-center w-full mt-5">
                  <button
                    onClick={(e) => {
                      if (editorContent !== originalSummary) {
                        e.preventDefault();
                        setShowWarning(true);
                      } else {
                        setShowWarning(false);
                        // Proceed with opening the modal
                        // The data-hs-overlay attribute should handle opening the modal
                      }
                    }}
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent text-white bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    {...(editorContent === originalSummary && {
                      'data-hs-overlay': '#hs-modal-continue-generation',
                    })}
                  >
                    Continue generation
                  </button>
                </div>
              )}
            </>
          ) : (
            <ul className="space-y-3 animate-pulse">
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
            </ul>
          )}
        </div>

        <div>
          <div className="justify-center pt-16 pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut">
            <h2 className="text-xl font-semibold text-center">Audio Recap</h2>
          </div>
          {narrationAudioURL ? (
            <div className="flex flex-col gap-4 items-center justify-center mt-4">
              <audio controls className="">
                <source src={narrationAudioURL} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
              <button
                onClick={() =>
                  downloadFile(
                    narrationAudioFilePath,
                    sanitizeSessionName(sessionName) + ' - Recapped on Scrybe' + '.mp3'
                  )
                }
                className="py-2 px-3 flex items-center whitespace-nowrap gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                >
                <HiDownload className="h-4 w-4" />
                Download
              </button>


            </div>
          ) : (
            <ul className="space-y-3 animate-pulse">
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
            </ul>
          )}
        </div>

        <div>
          <div className="justify-center pt-16 pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut">
            <h2 className="text-xl font-semibold text-center">Video Recap</h2>
          </div>
          {narrationVideoURL ? (
            <div className="flex flex-col gap-4 items-center justify-center mt-4">
              <video
                controls
                playsInline
                className="shadow-2xl rounded-3xl w-[40rem]"
              >
                <source src={narrationVideoURL} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <button
                onClick={() =>
                  downloadFile(
                    narrationVideoURL,
                    sanitizeSessionName(sessionName) + ' - Recapped on Scrybe' + '.mp4'
                  )
                }
                className="py-2 px-3 flex items-center whitespace-nowrap gap-x-2 font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
              >
                <HiDownload className="h-4 w-4" />
                Download
              </button>
            </div>
          ) : (
            <ul className="space-y-3 animate-pulse">
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
            </ul>
          )}
        </div>

        {/*<!-- Icon Blocks -->*/}
        <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-4 sm:gap-12 mt-12">
          {/*<!-- Icon Block -->*/}
          <div className="text-center rounded-md border border-gray-200 p-5">
            <div className="flex justify-center items-center w-8 h-8 bg-gray-50 border border-gray-200 rounded-full mx-auto dark:bg-neutral-800 dark:border-neutral-700">
              <WizardMicrophoneIcon className="h-4 w-4" />
            </div>
            <div className="mt-3">
              <h3 className="text-sm flex justify-center font-semibold text-gray-800 dark:text-white">
                {narrator ? narrator.name : 'Narrator'}
              </h3>
              {/* <p className="mt-1 text-gray-600 dark:text-neutral-400">Responsive, and mobile-first project on the web</p> */}
            </div>
          </div>
          {/*<!-- End Icon Block -->*/}

          {/*<!-- Icon Block -->*/}
          <div className="text-center rounded-md border border-gray-200 p-5">
            <div className="flex justify-center items-center w-8 h-8 bg-gray-50 border border-gray-200 rounded-full mx-auto dark:bg-neutral-800 dark:border-neutral-700">
              <IoMusicalNotes className="h-4 w-4 " />
            </div>
            <div className="mt-3">
              <h3 className="text-sm flex justify-center font-semibold text-gray-800 dark:text-white">
                {backgroundMusicName ? backgroundMusicName : 'Background Music'}
              </h3>
              {/* <p className="mt-1 text-gray-600 dark:text-neutral-400">Components are easily customized and extendable</p> */}
            </div>
          </div>
          {/*<!-- End Icon Block -->*/}
        </div>
        {/*<!-- End Icon Blocks -->*/}

      </div>
      <ContinueGenerationModal
        generateRecapFromSummary={generateRecapFromSummary}
      />
    </div>
  );
}

export default SessionPage;
