import React from 'react';

const TextBox = ({ title, content }) => {
  return (
    <div className="dots-corners border border-y-gray-200 w-full flex flex-col bg-white border-x-2 border-black p-4 dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7] dark:text-gray-400">
      {title && (
        <>
          <b className="text-center">{title}</b>
          <div className="border-b-2 w-full my-2"></div>
        </>
      )}
      <div className="text-left text-sm max-h-64 overflow-y-scroll focus:outline-none space-y-4 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400">
        {/* <div contentEditable="true" className="text-left text-sm focus:outline-none space-y-4 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 max-h-60 overflow-y-auto"> */}
        {typeof content === 'string' &&
          content
            .split(/\n+/)
            .map((segment, index) => <p key={index}>{segment}</p>)}
      </div>
    </div>
  );
};

export default TextBox;
